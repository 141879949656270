import React from 'react';
import { Field, Label, Input, Error } from '../styles/Input.styled';

const InputCompnent = ({
	name,
	type,
	value,
	placeholder,
	handleChange,
	validaton,
	formNoValidate,
	autoComplete,
}) => (
	<Field>
		<Label htmlFor={name} aria-label={name}>
			{placeholder}
		</Label>
		<Input
			type={type}
			novalidate
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={handleChange}
			autoComplete={autoComplete}
		/>
		{validaton && <Error>{validaton}</Error>}
	</Field>
);

export default InputCompnent;
