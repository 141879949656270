import React from 'react';
import Icon from '../../Icon';

const Success = () => (
	<>
		<Icon type="fas" icon="check-circle" color="#d97706" />
		<h2>Thank you for reaching out! Will be in touch shortly.</h2>
	</>
);

export default Success;
