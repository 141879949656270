import React, { useState } from 'react';
import InputCompnent from './components/Input';
import TextareaComponent from './components/Textarea';
import Success from './components/Success';
import handleChange from './utils/handleChange';
import handleSubmit from './utils/handleSubmit';
import { Form, Container, Button } from './styles/index.styled';
import Loader from '../Loader';

const ContactForm = () => {
	const [data, setData] = useState({
		name: '',
		email: '',
		phone: '',
		message: '',
	});
	const [validation, setValidation] = useState({
		name: '',
		email: '',
		phone: '',
	});
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	if (success)
		return (
			<Container>
				<Success />
			</Container>
		);
	if (loading)
		return (
			<Container>
				<Loader />
			</Container>
		);
	return (
		<Form
			onSubmit={e =>
				handleSubmit(e, data, setValidation, setSuccess, setLoading)
			}
		>
			<InputCompnent
				name="name"
				type="text"
				value={data.name}
				placeholder="Name"
				validaton={validation.name}
				handleChange={e => handleChange(e, data, setData, setValidation)}
				autoComplete="name"
			/>
			<InputCompnent
				name="email"
				type="text"
				value={data.email}
				placeholder="Email Address"
				validaton={validation.email}
				formNoValidate
				handleChange={e => handleChange(e, data, setData, setValidation)}
				autoComplete="email"
			/>
			<InputCompnent
				name="phone"
				type="tel"
				value={data.phone}
				placeholder="Phone Number"
				validaton={validation.phone}
				formNoValidate
				handleChange={e => handleChange(e, data, setData, setValidation)}
				autoComplete="tel"
			/>
			<TextareaComponent
				name="message"
				value={data.message}
				placeholder="Message"
				handleChange={e => handleChange(e, data, setData, setValidation)}
			/>
			<Button type="submit">Submit</Button>
		</Form>
	);
};

export default ContactForm;
