import axios from 'axios';

const handleSubmit = async (e, data, setValidation, setSuccess, setLoading) => {
	e.preventDefault();
	setLoading(true);
	try {
		await axios.post('/api/form', JSON.stringify(data), {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		setLoading(false);
		setSuccess(true);
	} catch (error) {
		setLoading(false);
		const { field, message } = error.response.data;
		setValidation({ [field]: message });
		setSuccess(false);
	}
};

export default handleSubmit;
