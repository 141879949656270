import styled from 'styled-components';

export const Form = styled.form`
	width: 100%;
	height: auto;
`;
export const Container = styled.div`
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	svg {
		margin-bottom: 24px;
	}
	h2 {
		font-size: 24px;
		max-width: 370px;
		font-weight: 600;
	}
`;
export const Button = styled.button`
	width: 100%;
	height: 47px;
	color: #ffffff;
	border-radius: 4px;
	background: var(--primary) !important;
`;
