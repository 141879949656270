const handleChange = (e, data, setData, setValidation) => {
	setValidation({
		name: '',
		email: '',
		phone: '',
	});
	setData(
		Object.assign({}, data, {
			[e.target.name]: e.target.value,
		})
	);
};

export default handleChange;
