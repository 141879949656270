import React from 'react';
import styled from 'styled-components';

const Loader = () => <Spinner class="loader" />;

export default Loader;

const Spinner = styled.svg`
	border: 12px solid #f3f3f3;
	border-top: 12px solid var(--primary);
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
