import styled from 'styled-components';

export const Field = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 12px;
`;
export const Label = styled.label`
	display: none;
`;
export const Input = styled.input`
	width: 100%;
	height: 47px;
	border: 1px solid rgb(81, 86, 103);
	box-shadow: rgb(22 29 37 / 5%) 0px 4px 2px;
	border-radius: 4px;
	margin-bottom: 8px;
	padding: 12px;
	font-weight: 600;
`;
export const Error = styled.p`
	color: red;
	font-weight: 700;
	font-size: 0.875rem;
`;
