import React from 'react';
import { Field, Label, Textarea } from '../styles/Textarea.styled';

const TextareaComponent = ({
	name,
	value,
	placeholder,
	handleChange,
	autoComplete,
}) => (
	<Field>
		<Label htmlFor={name} aria-label={name}>
			{placeholder}
		</Label>
		<Textarea
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={handleChange}
			autoComplete={autoComplete}
			rows={5}
			cols={30}
		/>
	</Field>
);

export default TextareaComponent;
